<template>
  <div>
    <park-info-header></park-info-header>
    <div class="list">
      <div v-infinite-scroll="onReachBottom" infinite-scroll-disabled="loading" infinite-scroll-distance="10">
        <div class="listCt" @click="inforClick(item.id)" v-for="(item, index) in list" :key="index">
          <div class="listLeft">
            <p class="title">{{ item.title }}</p>
            <p class="time"><img class="time_img" src="../../../assets/img/park/date.png" />{{ item.updateTime }}</p>
          </div>
          <div class="listRight">
            <img v-if="item.imagePath" :src="item.imagePath + '?x-oss-process=image/resize,h_100'" />
          </div>
        </div>
      </div>
      <div v-if="!loading" class="nodata">已无更多数据</div>
      <div v-if="!isEnd && loading" class="loading">
        <mt-spinner color="#178CF9" type="snake"></mt-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import ParkInfoHeader from '@/components/ParkInfoHeader/index'
import { listNews } from '@/api/park'

export default {
  components: {
    ParkInfoHeader
  },
  data() {
    return {
      loading: false,
      pageNo: 0,
      isEnd: false,
      list: []
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    // 详情页
    inforClick(id) {
      this.$router.push(`/dynamic/detail/${id}`)
    },
    refresh() {
      this.isEnd = false
      this.list = []
      this.loadData(1)
    },
    async loadData(pageNo) {
      this.loading = true
      try {
        const { success, result } = await listNews({
          pageSize: 10,
          pageNo
        })
        const totalPage = result.pages
        if (success) {
          if (+pageNo === 1) {
            this.list = result.records
          } else {
            this.list = this.list.concat(result.records)
          }
          this.pageNo = pageNo
          if (this.pageNo >= totalPage) {
            this.isEnd = true
          }
        }
      } catch (error) {
        this.isEnd = true
      } finally {
        this.loading = false
      }
    },
    onReachBottom() {
      if (this.isEnd) return
      this.loadData(this.pageNo + 1)
    }
  }
}
</script>

<style lang="less" scoped>
.list {
  padding: 0 15px;
}

.system-message-box {
  height: 94vh;
  overflow: scroll;
  margin-bottom: 50px;

  .load-all {
    /* 数据加载完毕提示信息 */
    padding-bottom: 15px;
    font-size: 14px;
    text-align: center;
    color: #c8c8cf;
  }
}

.listCt {
  padding-bottom: 18px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 18px;
  display: flex;

  .listLeft {
    width: 210px;
    margin-right: 26px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      color: #333;
      font-size: 16px;
      font-weight: 500;
      font-family: 'Source Han Sans CN';
      display: -webkit-box;
      -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
      overflow: hidden;
      white-space: normal !important;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
    }

    .time {
      font-size: 11px;
      color: #999999;
      font-family: 'Arial';
      display: flex;
      align-items: center;

      .time_img {
        margin-right: 6px;
        width: 10px;
        height: 10px;
      }
    }
  }

  .listRight {
    width: 120px;
    height: 70px;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;

    img {
      // width:100%;
      width: 120px;
      height: 70px;
    }
  }
}
</style>
